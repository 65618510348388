import React from 'react'
import Layout from "../components/Layout";

const liekarne = () => {
  return (
    <Layout fullMenu>
      <article id="main">
        <div className="prodajna_mesta_deo wrapper style5">

          <div className="naslovi">
            <p>Lekaren</p>
            <p>Ulica</p>
            <p>Sídlo</p>
          </div>

          <div className="prodajna_mesta_lista">
            <ul>
              <li className="prodajna_mesta_item">LEKAREN ABRAHÁM </li>
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER </li>
              <li className="prodajna_mesta_item">LEKAREN SILYBUM  SCHM "+" </li>
              <li className="prodajna_mesta_item">LEKAREN SUSCCH  "+" </li >
              <li className="prodajna_mesta_item">LEKAREN SPOJOVA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN ARCUS  SCHM  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN ATRIUM  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN MEDIUS  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN BELLA VITA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN PILULKA 24</li >
              <li className="prodajna_mesta_item">LEKAREN RUŽINOV</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN SOFIA</li >
              <li className="prodajna_mesta_item">LEKAREN V BILLA NOBELOVO NÁMESTIE</li >
              <li className="prodajna_mesta_item">LEKAREN HARMÓNIA</li >
              <li className="prodajna_mesta_item">LEKAREN ZUZANA CENTRUM    "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN VEREJNA NUDCH "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN POKROK</li >
              <li className="prodajna_mesta_item">LEKAREN VASA LEKAREN VIVO "VL" PR</li >
              <li className="prodajna_mesta_item">LEKAREN GREEN PHARMA SLNECNICE  "SB" </li >
              <li className="prodajna_mesta_item">LEKAREN BENU 84</li >
              <li className="prodajna_mesta_item">LEKAREN BOSAKOVA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN PILULKA 24 "+"</li >
              <li className="prodajna_mesta_item">LEKAREN PILULKA 24 "+"</li >
              <li className="prodajna_mesta_item">LEKAREN ZELENA FARMACIA  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN DR.MAX 055 "+"</li >
              <li className="prodajna_mesta_item">LEKAREN KUBACOVA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN VITAMIN  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN NA MIEROVEJ  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN MILOSRDNI BRATIA VER "+"</li >
              <li className="prodajna_mesta_item">LEKAREN AVION  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN AFRODITA</li >
              <li className="prodajna_mesta_item">LEKAREN BREZNO</li >
              <li className="prodajna_mesta_item">LEKAREN LIEKY24  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN DR.MAX 258 "+"</li >
              <li className="prodajna_mesta_item">LEKAREN NA NAMESTI SR 11  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN CHTELNICA</li >
              <li className="prodajna_mesta_item">LEKAREN OBECNA LEKAREN CIERNY BALOG  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN ČACHTICKÁ</li >
              <li className="prodajna_mesta_item">LEKAREN DIVÍN</li >
              <li className="prodajna_mesta_item">LEKÁREŇ ANNA</li >
              <li className="prodajna_mesta_item">LEKAREN NAJLEPSIA LEKAREN "+"</li >
              <li className="prodajna_mesta_item">LEKAREN ARTEMIDES</li >
              <li className="prodajna_mesta_item">EKAREN JEDNOROZEC  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN DR.MAX 144  "+"</li>
              <li className="prodajna_mesta_item">LEKAREN DR.MAX 298 "+"</li >
              <li className="prodajna_mesta_item">LEKAREN BERBERIS  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN MATRIX</li >
              <li className="prodajna_mesta_item">LEKAREN REMEDIA  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN POD TERASOU  "VL"</li >
              <li className="prodajna_mesta_item">VZP ZDRAVPOTREBY</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER  SCH "+"</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN TOMAS  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN ANJELA STRÁŽCU</li >
              <li className="prodajna_mesta_item">LEKAREN JELŠAVA</li >
              <li className="prodajna_mesta_item">LEKAREN HEDERA</li >
              <li className="prodajna_mesta_item">LEKAREN LUBKA  SICH  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN I.SEMMELWEISA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN ALBA  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN A + Z</li >
              <li className="prodajna_mesta_item">LEKAREN NA RÁKÓCZIHO ULICI</li >
              <li className="prodajna_mesta_item">LEKAREN TABLETKA</li >
              <li className="prodajna_mesta_item">LEKAREN AMBRA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN AVION  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN VZP ALEX</li >
              <li className="prodajna_mesta_item">LEKAREN IZIS  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN TIP - TOP  SCH "+"</li >
              <li className="prodajna_mesta_item">LEKAREN AMBRA</li >
              <li className="prodajna_mesta_item">LEKAREN JUH</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN TERASA</li >
              <li className="prodajna_mesta_item">LEKAREN HELIANTHUS  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN FICARIA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN MESTSKA  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN MELISSA</li >
              <li className="prodajna_mesta_item">LEKAREN APIS  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN SANTE</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN ZDRAVIE</li >
              <li className="prodajna_mesta_item">LEKAREN EGI  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN KAMJANA</li >
              <li className="prodajna_mesta_item">LEKAREN KAMJANA  SCH "+"</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN SALVATOR</li >
              <li className="prodajna_mesta_item">LEKAREN DR.MAX 319 "+"</li >
              <li className="prodajna_mesta_item">LEKAREN BENU 37</li >
              <li className="prodajna_mesta_item">LEKAREN SV.MICHALA</li >
              <li className="prodajna_mesta_item">LEKAREN ZDENKA  "VL"</li>
              <li className="prodajna_mesta_item">LEKAREN U VALENTINA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN ZDENKA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN ZDENKA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN NITRIANSKE RUDNO</li >
              <li className="prodajna_mesta_item">LEKAREN SARA MEDICA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN DR.MAX 453  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN TINA</li >
              <li className="prodajna_mesta_item">LEKAREN CLAUDIA</li >
              <li className="prodajna_mesta_item">LEKAREN NA NÁMESTÍ</li >
              <li className="prodajna_mesta_item">LEKAREN SVÄTEJ ALŽBETY</li >
              <li className="prodajna_mesta_item">LEKAREN TRI MOSTY</li >
              <li className="prodajna_mesta_item">LEKAREN SANUS  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN PRI POHOTOVOSTI  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN SV.TADEÁŠA</li >
              <li className="prodajna_mesta_item">LEKAREN VEREJNA NURCH S.P.O. "+"</li >
              <li className="prodajna_mesta_item">LEKAREN SV.LUKASA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN PHARMACIA  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN U BROJA "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN VZP UNIZDRAV - FRANTISKANSKE NAM.</li >
              <li className="prodajna_mesta_item">LEKAREN PHARMACIA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN AMULETUM</li >
              <li className="prodajna_mesta_item">LEKAREN LIBRA</li >
              <li className="prodajna_mesta_item">LEKAREN PHARMA - CENTRUM</li >
              <li className="prodajna_mesta_item">LEKAREN SOLIVARIA</li >
              <li className="prodajna_mesta_item">LEKAREN ZEUS</li >
              <li className="prodajna_mesta_item">LEKAREN U RAKA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN NA SÍDLISKU</li >
              <li className="prodajna_mesta_item">LEKAREN STARE MESTO  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN STASTIE  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN PANORAMA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN PANORÁMA</li >
              <li className="prodajna_mesta_item">LEKÁREŇ ZÁPAD</li >
              <li className="prodajna_mesta_item">LEKAREN RUZA  SCHM  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN NA NÁMESTÍ</li >
              <li className="prodajna_mesta_item">LEKAREN VZP EDAS</li >
              <li className="prodajna_mesta_item">LEKAREN PATIKA PRI STANICI  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN NA NAMESTI "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN OLMED  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN CENTRUM</li >
              <li className="prodajna_mesta_item">LEKAREN NA POTOCNEJ PREB U  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN AMARA</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER SCH "+"</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN ELIXIR   "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN U SV.PETRA A JAKUBA "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN LACRIMULA</li >
              <li className="prodajna_mesta_item">LEKAREN ŠÚROVCE</li >
              <li className="prodajna_mesta_item">LEKAREN SALVE "+"</li >
              <li className="prodajna_mesta_item">LEKAREN MELISSA</li >
              <li className="prodajna_mesta_item">LEKAREN VZP JST ZDRAVIE</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN NA SIHOTI  DL "+"</li >
              <li className="prodajna_mesta_item">LEKAREN IPACIENT "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN VEREJNA FN TRNAVA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN CASSALE   "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN LEONARDO "+"</li >
              <li className="prodajna_mesta_item">LEKAREN BENU 94</li >
              <li className="prodajna_mesta_item">LEKAREN TURCIANSKA "+"</li >
              <li className="prodajna_mesta_item">LEKAREN VIOLA  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN BENU 71</li >
              <li className="prodajna_mesta_item">LEKAREN PATIKA  "+"</li >
              <li className="prodajna_mesta_item">LEKAREN MELISSA</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
              <li className="prodajna_mesta_item">LEKAREN SAKULI  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN VZP NZZ</li >
              <li className="prodajna_mesta_item">LEKAREN VZP AD CENTRUM</li >
              <li className="prodajna_mesta_item">LEKAREN BENU 13</li >
              <li className="prodajna_mesta_item">LEKAREN MARKO  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN U SPASITEĽA</li >
              <li className="prodajna_mesta_item">LEKAREN V KOCKE  "VL"</li >
              <li className="prodajna_mesta_item">LEKAREN AQUA</li >
              <li className="prodajna_mesta_item">LEKAREN AUPARK</li >
              <li className="prodajna_mesta_item">LEKAREN SCHNEIDER</li >
            </ul>

            <ul>
              <li className="prodajna_mesta_item">Abrahám 170          **</li>
              <li className="prodajna_mesta_item">Janka Jesenského 1892/11</li>
              <li className="prodajna_mesta_item">RUDOHORSKA 27</li>
              <li className="prodajna_mesta_item">CESTA K NEMOCNICI 1</li >
              <li className="prodajna_mesta_item">SPOJOVA 25</li >
              <li className="prodajna_mesta_item">NAM.L.SVOBODU 19</li >
              <li className="prodajna_mesta_item">PARTIZANSKA 4</li >
              <li className="prodajna_mesta_item">WOLKEROVA 11</li >
              <li className="prodajna_mesta_item">STUROVA 2454</li >
              <li className="prodajna_mesta_item">Podunajská 27</li >
              <li className="prodajna_mesta_item">Komárnická 11</li >
              <li className="prodajna_mesta_item">Obchodná 74</li >
              <li className="prodajna_mesta_item">Švabinského 17</li >
              <li className="prodajna_mesta_item">Nobelovo námestie 1</li >
              <li className="prodajna_mesta_item">Miletičova 40 **</li >
              <li className="prodajna_mesta_item">BORSKA 1</li >
              <li className="prodajna_mesta_item">LIMBOVA 1</li >
              <li className="prodajna_mesta_item">RACIANSKE MYTO 1 / A</li >
              <li className="prodajna_mesta_item">VAJNORSKA 10645 / 100</li >
              <li className="prodajna_mesta_item">ZUZANY CHALUPOVEJ 5</li >
              <li className="prodajna_mesta_item">MUCHOVO NAMESTIE 4</li >
              <li className="prodajna_mesta_item">BOSAKOVA 9</li >
              <li className="prodajna_mesta_item">PODUNAJSKA 27</li >
              <li className="prodajna_mesta_item">PODUNAJSKA 27</li >
              <li className="prodajna_mesta_item">UZBECKA 20 / A</li >
              <li className="prodajna_mesta_item">PUCHOVSKA UL.</li >
              <li className="prodajna_mesta_item">KUBACOVA 19</li >
              <li className="prodajna_mesta_item">ZAHRADNICKA 45</li >
              <li className="prodajna_mesta_item">MIEROVA 22</li >
              <li className="prodajna_mesta_item">NAMESTIE SNP 10</li >
              <li className="prodajna_mesta_item">FLORIANSKE NAMESTIE 2</li >
              <li className="prodajna_mesta_item">Nám.M.R.Štefánika 8</li >
              <li className="prodajna_mesta_item">Československej armády 67 / 19 **</li >
              <li className="prodajna_mesta_item">STUROVA 49</li >
              <li className="prodajna_mesta_item">HVIEZDOSLAVOVA 300</li >
              <li className="prodajna_mesta_item">NAMESTIE SR 11</li >
              <li className="prodajna_mesta_item">Nám. 1. mája 20</li >
              <li className="prodajna_mesta_item">ZAVODIE 5 / 5</li >
              <li className="prodajna_mesta_item">Malinovského 424</li >
              <li className="prodajna_mesta_item">Dr.Janského 658 / 6</li >
              <li className="prodajna_mesta_item">Nemocničná 1987 / 24</li >
              <li className="prodajna_mesta_item">NEMOCNICNA 1944 / 10</li >
              <li className="prodajna_mesta_item">Okružná 160</li >
              <li className="prodajna_mesta_item">ZELEZNICNA 4</li >
              <li className="prodajna_mesta_item">VELKOBLAHOVSKA 23</li >
              <li className="prodajna_mesta_item">HLAVNA 75</li >
              <li className="prodajna_mesta_item">NEMESSEGSKA 1</li >
              <li className="prodajna_mesta_item">Veľkoblahovská 7333 / 44A</li >
              <li className="prodajna_mesta_item">NAMESTIE BANIKOV 55</li >
              <li className="prodajna_mesta_item">HOLLEHO 1</li>
              <li className="prodajna_mesta_item">LABORECKA 18</li >
              <li className="prodajna_mesta_item">STEFANIKOVA 6295 / 16</li >
              <li className="prodajna_mesta_item">Štefánikova 6295 / 16</li >
              <li className="prodajna_mesta_item">NOVOZAMOCKA UL 743 / 416</li >
              <li className="prodajna_mesta_item">Sídlisko 894</li >
              <li className="prodajna_mesta_item">Tomášikova 481</li >
              <li className="prodajna_mesta_item">Nitrianska 6</li >
              <li className="prodajna_mesta_item">DR.DANIELA FISCHERA 2231</li >
              <li className="prodajna_mesta_item">SENNY TRH 799 / 6</li >
              <li className="prodajna_mesta_item">EOTVOSA 37</li >
              <li className="prodajna_mesta_item">Petöfiho 3985 / 17</li >
              <li className="prodajna_mesta_item">Rákocziho 3125          /// </li>
              <li className="prodajna_mesta_item">Hlboká 284 / 10</li >
              <li className="prodajna_mesta_item">HERLIANSKA 60</li >
              <li className="prodajna_mesta_item">RASTISLAVOVA 45</li >
              <li className="prodajna_mesta_item">NAMESTIE OCELIAROV 23</li >
              <li className="prodajna_mesta_item">CESTA POD HRADOVOU</li >
              <li className="prodajna_mesta_item">CESKOSLOVENSKEJ ARMADY 18</li >
              <li className="prodajna_mesta_item">OC ShopBox, Herlianska 60</li >
              <li className="prodajna_mesta_item">Rastislavova ulica 785 / 45</li >
              <li className="prodajna_mesta_item">Protifašistických bojovníkov č. 3378 / 11</li >
              <li className="prodajna_mesta_item">Trieda SNP 88</li >
              <li className="prodajna_mesta_item">KOVARCE 6</li >
              <li className="prodajna_mesta_item">HLAVNA 717 / 174</li >
              <li className="prodajna_mesta_item">PRI POLIKLINIKE</li >
              <li className="prodajna_mesta_item">Pri Podlužianke 8</li >
              <li className="prodajna_mesta_item">NABREZNA 3</li >
              <li className="prodajna_mesta_item">Nám.sv.Martina 9</li >
              <li className="prodajna_mesta_item">Kamenné pole 4447 / 2</li >
              <li className="prodajna_mesta_item">Jánošíkovo nábrežie 1212 / 2 - prízemie</li >
              <li className="prodajna_mesta_item">Ľ.Fullu 5274</li >
              <li className="prodajna_mesta_item">ZAMOCKA 1066</li >
              <li className="prodajna_mesta_item">Komenského 134 / 4</li >
              <li className="prodajna_mesta_item">KOMENSKEHO 134 / 4</li >
              <li className="prodajna_mesta_item">Ul.prof.Hlaváča č. 1885 / 27</li >
              <li className="prodajna_mesta_item">Dukelská 29</li >
              <li className="prodajna_mesta_item">DUKELSKA 10</li >
              <li className="prodajna_mesta_item">VIESTOVA 5</li >
              <li className="prodajna_mesta_item">Hviezdoslavova 45</li >
              <li className="prodajna_mesta_item">HORNOCERMANSKA 4</li >
              <li className="prodajna_mesta_item">DLHA 2</li >
              <li className="prodajna_mesta_item">HORNOCERMANSKA 4</li >
              <li className="prodajna_mesta_item">HORNOCERMANSKA 4</li >
              <li className="prodajna_mesta_item">HLAVNA 171 / 49</li >
              <li className="prodajna_mesta_item">MIEROVE NAMESTIE 30 / 14</li >
              <li className="prodajna_mesta_item">DIBROVOVA 30</li >
              <li className="prodajna_mesta_item">HM Tesco, Trenčianska 2492 / 68</li >
              <li className="prodajna_mesta_item">Klčové 2089 / 39</li >
              <li className="prodajna_mesta_item">Gúrska 14 **</li >
              <li className="prodajna_mesta_item">Hlavné námestie 3 **</li >
              <li className="prodajna_mesta_item">G.Czuczora 1</li >
              <li className="prodajna_mesta_item">Šurianska 7</li >
              <li className="prodajna_mesta_item">NADRAZNA 686 / 4</li >
              <li className="prodajna_mesta_item">HRNCIRIKOVA 7</li >
              <li className="prodajna_mesta_item">Holubyho 41</li >
              <li className="prodajna_mesta_item">NABREZIE IVANA KRASKU 4</li >
              <li className="prodajna_mesta_item">NEMOCNICNA 986</li >
              <li className="prodajna_mesta_item">TATRANSKA 304</li >
              <li className="prodajna_mesta_item">TATRANSKA 293</li >
              <li className="prodajna_mesta_item">FRANTISKANSKE NAMESTIE 3 / A</li >
              <li className="prodajna_mesta_item">SEBASTOVSKA 4</li >
              <li className="prodajna_mesta_item">Sabinovská 15</li >
              <li className="prodajna_mesta_item">Justičná 15</li >
              <li className="prodajna_mesta_item">Sídlisko III centrum</li >
              <li className="prodajna_mesta_item">OC Solivaria, Solivarská 14839 / 1C **</li >
              <li className="prodajna_mesta_item">Levočská 18</li >
              <li className="prodajna_mesta_item">RAKOVO 94</li >
              <li className="prodajna_mesta_item">Svätoplukova 5          -- -</li >
              <li className="prodajna_mesta_item">HVIEZDOSLAVOVA 1</li >
              <li className="prodajna_mesta_item">SVATOPLUKOVA 90 / 14</li >
              <li className="prodajna_mesta_item">ZELEZNICNA 21</li >
              <li className="prodajna_mesta_item">Železničná 21</li >
              <li className="prodajna_mesta_item">V.Clementisa 4754</li >
              <li className="prodajna_mesta_item">UL.DONCOVA 1455 / 2</li >
              <li className="prodajna_mesta_item">Námestie slobody 45</li >
              <li className="prodajna_mesta_item">HOLLEHO 9</li >
              <li className="prodajna_mesta_item">AGATOVY RAD 51</li >
              <li className="prodajna_mesta_item">NAMESTIE SLOBODY 946</li >
              <li className="prodajna_mesta_item">MIEROVE NAMESTIE 6</li >
              <li className="prodajna_mesta_item">Štefánikova 726 / 2</li >
              <li className="prodajna_mesta_item">POTOCNA 189 / 43</li >
              <li className="prodajna_mesta_item">Fučíkova 96</li >
              <li className="prodajna_mesta_item">OBRANCOV MIERU 8</li >
              <li className="prodajna_mesta_item">Obrancov mieru 8</li >
              <li className="prodajna_mesta_item">AKADEMIKA PAVLOVA 312 / 11</li >
              <li className="prodajna_mesta_item">MUDR.PRIBULU 149</li >
              <li className="prodajna_mesta_item">Balassiho 62</li >
              <li className="prodajna_mesta_item">Hlavná 560 / 28</li >
              <li className="prodajna_mesta_item">UL.P.O.HVIEZDOSLAVA 3797</li >
              <li className="prodajna_mesta_item">Továrnická 1029 / 12 **</li >
              <li className="prodajna_mesta_item">J.ZABORSKEHO 3718  6 / A</li >
              <li className="prodajna_mesta_item">Hypermarket Tesco, M.R.Štefánika č. 3824 / 208</li >
              <li className="prodajna_mesta_item">MARTINA RAZUSA 1471 / 36</li >
              <li className="prodajna_mesta_item">NAM. SV. ANNY 357/19</li>
              <li className="prodajna_mesta_item">ANDREJA ZARNOVA 11</li >
              <li className="prodajna_mesta_item">BEETHOVENOVA 28</li >
              <li className="prodajna_mesta_item">JANA HOLLEHO 5</li >
              <li className="prodajna_mesta_item">HOSPODARSKA 53</li >
              <li className="prodajna_mesta_item">SNP 544</li >
              <li className="prodajna_mesta_item">ZELEZNICNA 4</li >
              <li className="prodajna_mesta_item">NEMOCNICNA 757</li >
              <li className="prodajna_mesta_item">NAM.BELU BARTOKA 215</li >
              <li className="prodajna_mesta_item">Hlavná 171</li >
              <li className="prodajna_mesta_item">M.R.Štefánika 876</li >
              <li className="prodajna_mesta_item">SKOLSKA 569</li >
              <li className="prodajna_mesta_item">MIEROVA 26</li >
              <li className="prodajna_mesta_item">MIEROVA 15 / 45</li >
              <li className="prodajna_mesta_item">VYSOKOSKOLAKOV 52</li >
              <li className="prodajna_mesta_item">POSTOVA ULICA 778 / 2</li >
              <li className="prodajna_mesta_item">Kozačeka 9282          ///</li>
              <li className="prodajna_mesta_item">E.P.VOLJANSKEHO 1</li >
              <li className="prodajna_mesta_item">Dolná 247 / 4          ///</li>
              <li className="prodajna_mesta_item">Štúrovo námestie 1 - LEKÁREŇ</li >
              <li className="prodajna_mesta_item">Mirage Shoping Center, Nám.A.Hlinku 7 / B</li >
            </ul>

            <ul>
              <li className="prodajna_mesta_item">Abrahám</li>
              <li className="prodajna_mesta_item">Bánovce nad Bebravou</li>
              <li className="prodajna_mesta_item">BANSKA BYSTRICA</li>
              <li className="prodajna_mesta_item">BANSKA BYSTRICA</li >
              <li className="prodajna_mesta_item">BANSKA BYSTRICA</li >
              <li className="prodajna_mesta_item">BANSKA BYSTRICA</li >
              <li className="prodajna_mesta_item">BARDEJOV</li >
              <li className="prodajna_mesta_item">BARDEJOV</li >
              <li className="prodajna_mesta_item">BELUSA</li >
              <li className="prodajna_mesta_item">Bratislava</li >
              <li className="prodajna_mesta_item">Bratislava</li >
              <li className="prodajna_mesta_item">Bratislava</li >
              <li className="prodajna_mesta_item">Bratislava</li>
              <li className="prodajna_mesta_item">Bratislava</li >
              <li className="prodajna_mesta_item">Bratislava - mestská časť Ružinov</li >
              <li className="prodajna_mesta_item">BRATISLAVA DUBRAVKA</li >
              <li className="prodajna_mesta_item">BRATISLAVA KRAMARE</li >
              <li className="prodajna_mesta_item">BRATISLAVA NOV.MESTO</li >
              <li className="prodajna_mesta_item">BRATISLAVA NOV.MESTO</li >
              <li className="prodajna_mesta_item">BRATISLAVA PETRZALKA</li >
              <li className="prodajna_mesta_item">BRATISLAVA PETRZALKA</li >
              <li className="prodajna_mesta_item">BRATISLAVA PETRZALKA</li >
              <li className="prodajna_mesta_item">BRATISLAVA POD.BISK.</li >
              <li className="prodajna_mesta_item">BRATISLAVA POD.BISK.</li >
              <li className="prodajna_mesta_item">BRATISLAVA POD.BISK.</li >
              <li className="prodajna_mesta_item">BRATISLAVA RACA</li >
              <li className="prodajna_mesta_item">BRATISLAVA RACA</li >
              <li className="prodajna_mesta_item">BRATISLAVA RUZINOV</li >
              <li className="prodajna_mesta_item">BRATISLAVA RUZINOV</li >
              <li className="prodajna_mesta_item">BRATISLAVA ST.MESTO</li >
              <li className="prodajna_mesta_item">BRATISLAVA ST.MESTO</li >
              <li className="prodajna_mesta_item">Brezno</li >
              <li className="prodajna_mesta_item">Brezno</li >
              <li className="prodajna_mesta_item">BREZNO</li >
              <li className="prodajna_mesta_item">BYTCA</li >
              <li className="prodajna_mesta_item">BYTCA</li >
              <li className="prodajna_mesta_item">Chtelnica</li >
              <li className="prodajna_mesta_item">CIERNY BALOG</li >
              <li className="prodajna_mesta_item">Čachtice</li >
              <li className="prodajna_mesta_item">Divín</li >
              <li className="prodajna_mesta_item">Dolný Kubín</li >
              <li className="prodajna_mesta_item">DOLNY KUBIN BREZOVEC</li >
              <li className="prodajna_mesta_item">Dudince</li >
              <li className="prodajna_mesta_item">DUNAJSKA STREDA</li >
              <li className="prodajna_mesta_item">DUNAJSKA STREDA</li >
              <li className="prodajna_mesta_item">DUNAJSKA STREDA</li >
              <li className="prodajna_mesta_item">DUNAJSKA STREDA</li >
              <li className="prodajna_mesta_item">Dunajská Streda</li >
              <li className="prodajna_mesta_item">GELNICA</li >
              <li className="prodajna_mesta_item">HLOHOVEC</li >
              <li className="prodajna_mesta_item">HUMENNE</li >
              <li className="prodajna_mesta_item">HUMENNE</li >
              <li className="prodajna_mesta_item">Humenné</li >
              <li className="prodajna_mesta_item">IVANKA PRI NITRE</li >
              <li className="prodajna_mesta_item">Jablonica</li >
              <li className="prodajna_mesta_item">Jelšava</li >
              <li className="prodajna_mesta_item">Kalná nad Hronom</li >
              <li className="prodajna_mesta_item">KEZMAROK</li >
              <li className="prodajna_mesta_item">KOMARNO</li >
              <li className="prodajna_mesta_item">KOMARNO</li >
              <li className="prodajna_mesta_item">Komárno</li >
              <li className="prodajna_mesta_item">Komárno</li >
              <li className="prodajna_mesta_item">Komárno</li >
              <li className="prodajna_mesta_item">KOSICE DARG.HRDINOV</li >
              <li className="prodajna_mesta_item">KOSICE JUH</li >
              <li className="prodajna_mesta_item">KOSICE SACA</li >
              <li className="prodajna_mesta_item">KOSICE SEVER</li >
              <li className="prodajna_mesta_item">KOSICE STARE MESTO</li >
              <li className="prodajna_mesta_item">Košice</li >
              <li className="prodajna_mesta_item">Košice</li >
              <li className="prodajna_mesta_item">Košice</li >
              <li className="prodajna_mesta_item">Košice</li >
              <li className="prodajna_mesta_item">KOVARCE</li >
              <li className="prodajna_mesta_item">KRALOVSKY CHLMEC</li >
              <li className="prodajna_mesta_item">KYSUCKE NOVE MESTO</li >
              <li className="prodajna_mesta_item">Levice</li >
              <li className="prodajna_mesta_item">LEVICE</li >
              <li className="prodajna_mesta_item">Lipany</li >
              <li className="prodajna_mesta_item">Liptovský Mikuláš</li >
              <li className="prodajna_mesta_item">Liptovský Mikuláš</li >
              <li className="prodajna_mesta_item">Malacky</li >
              <li className="prodajna_mesta_item">MALACKY</li >
              <li className="prodajna_mesta_item">Medzilaborce</li >
              <li className="prodajna_mesta_item">MEDZILABORCE</li >
              <li className="prodajna_mesta_item">Michalovce</li >
              <li className="prodajna_mesta_item">Modra</li >
              <li className="prodajna_mesta_item">MODRA</li >
              <li className="prodajna_mesta_item">MYJAVA</li >
              <li className="prodajna_mesta_item">Námestovo</li >
              <li className="prodajna_mesta_item">NITRA</li >
              <li className="prodajna_mesta_item">NITRA</li >
              <li className="prodajna_mesta_item">NITRA</li >
              <li className="prodajna_mesta_item">NITRA</li >
              <li className="prodajna_mesta_item">NITRIANSKE RUDNO</li >
              <li className="prodajna_mesta_item">NOVA DUBNICA</li >
              <li className="prodajna_mesta_item">NOVE MESTO NAD VAHOM</li >
              <li className="prodajna_mesta_item">Nové Mesto nad Váhom</li >
              <li className="prodajna_mesta_item">Nové Mesto nad Váhom</li >
              <li className="prodajna_mesta_item">Nové Zámky</li >
              <li className="prodajna_mesta_item">Nové Zámky</li >
              <li className="prodajna_mesta_item">Nové Zámky</li >
              <li className="prodajna_mesta_item">Nové Zámky</li >
              <li className="prodajna_mesta_item">PARTIZANSKE</li >
              <li className="prodajna_mesta_item">PARTIZANSKE</li >
              <li className="prodajna_mesta_item">Pezinok</li >
              <li className="prodajna_mesta_item">PIESTANY</li >
              <li className="prodajna_mesta_item">POVAZSKA BYSTRICA</li >
              <li className="prodajna_mesta_item">POVAZSKA BYSTRICA</li >
              <li className="prodajna_mesta_item">POVAZSKA BYSTRICA</li >
              <li className="prodajna_mesta_item">PRESOV</li >
              <li className="prodajna_mesta_item">PRESOV NIZ.SEBASTOVA</li >
              <li className="prodajna_mesta_item">Prešov</li >
              <li className="prodajna_mesta_item">Prešov</li >
              <li className="prodajna_mesta_item">Prešov</li >
              <li className="prodajna_mesta_item">Prešov</li >
              <li className="prodajna_mesta_item">Prešov</li >
              <li className="prodajna_mesta_item">PRIBOVCE</li >
              <li className="prodajna_mesta_item">Prievidza</li >
              <li className="prodajna_mesta_item">PRIEVIDZA</li >
              <li className="prodajna_mesta_item">PRIEVIDZA</li >
              <li className="prodajna_mesta_item">REVUCA</li >
              <li className="prodajna_mesta_item">Revúca</li >
              <li className="prodajna_mesta_item">Rimavská Sobota</li >
              <li className="prodajna_mesta_item">RUZOMBEROK</li >
              <li className="prodajna_mesta_item">Sabinov</li >
              <li className="prodajna_mesta_item">SALA</li >
              <li className="prodajna_mesta_item">SAMORIN</li >
              <li className="prodajna_mesta_item">SASTIN - STRAZE</li >
              <li className="prodajna_mesta_item">SENEC</li >
              <li className="prodajna_mesta_item">Senica</li >
              <li className="prodajna_mesta_item">SKALICA</li >
              <li className="prodajna_mesta_item">Sládkovičovo</li >
              <li className="prodajna_mesta_item">STARA LUBOVNA</li >
              <li className="prodajna_mesta_item">Stará Ľubovňa</li >
              <li className="prodajna_mesta_item">STROPKOV</li >
              <li className="prodajna_mesta_item">SVIDNIK</li >
              <li className="prodajna_mesta_item">Štúrovo</li >
              <li className="prodajna_mesta_item">Šúrovce</li >
              <li className="prodajna_mesta_item">TOPOLCANY</li >
              <li className="prodajna_mesta_item">Topoľčany</li >
              <li className="prodajna_mesta_item">TREBISOV</li >
              <li className="prodajna_mesta_item">Trebišov</li >
              <li className="prodajna_mesta_item">TRENCIN</li >
              <li className="prodajna_mesta_item">TRENCIN</li >
              <li className="prodajna_mesta_item">TRNAVA</li >
              <li className="prodajna_mesta_item">TRNAVA</li >
              <li className="prodajna_mesta_item">TRNAVA</li >
              <li className="prodajna_mesta_item">TRNAVA</li >
              <li className="prodajna_mesta_item">TURCIANSKE TEPLICE</li >
              <li className="prodajna_mesta_item">TVRDOSOVCE</li >
              <li className="prodajna_mesta_item">VELKY KRTIS</li >
              <li className="prodajna_mesta_item">VELKY MEDER</li >
              <li className="prodajna_mesta_item">Vráble</li >
              <li className="prodajna_mesta_item">Vranov nad Topľou</li >
              <li className="prodajna_mesta_item">ZABOKREKY NAD NITROU</li >
              <li className="prodajna_mesta_item">ZELIEZOVCE</li >
              <li className="prodajna_mesta_item">ZELIEZOVCE</li>
              <li className="prodajna_mesta_item">ZILINA</li >
              <li className="prodajna_mesta_item">ZLATE KLASY</li >
              <li className="prodajna_mesta_item">Zvolen</li >
              <li className="prodajna_mesta_item">ZVOLEN</li >
              <li className="prodajna_mesta_item">Žarnovica</li >
              <li className="prodajna_mesta_item">Žilina</li >
              <li className="prodajna_mesta_item">Žilina</li >
            </ul>

          </div>
        </div>
      </article>

    </Layout>
  )
}

export default liekarne
